import {
    AccountType,
    AggregatedAnalyticsByProperty,
    CumulativeBundleAnalytics,
    EmissionCalculationMetrics,
    EmissionFactorLogicalUnit,
    ShippingEmissionTimeseries,
} from '@lune-climate/lune'

import { AccountMini } from 'components/AccountFilter'
import { bootstrapAxiosClient, bootstrapAxiosClientNoInterceptors } from 'endpoints/util'
import { IAccount } from 'models/account'
import { IActivity } from 'models/activity'
import { ApiKeyStatus, IApiKey, IApiKeyWithSecret } from 'models/apiKey'
import {
    AnalyticsMetrics,
    CreateApiKeyRequest,
    CreatePaymentMethodResponse,
    EmissionFactor,
    Errors as DapiErrorsResponse,
    LogisticsSheet,
    Organisation,
    OrganisationUsers,
    PaginatedEmissionFactors,
    PaginatedEstimatesWithAccountInfo,
    PaginatedOrdersWithAccountInfo,
    PaginatedProposals,
    PatchOrganisationRequest,
    PaymentMethodInitiation,
    PaymentMethods,
    ProposalRequest,
    ProposalResponse,
    PublicProposalResponse,
} from 'models/openDapi'
import { OrderStatus } from 'models/order'
import { IPaginationQueryParams, IPaginationResult } from 'models/pagination'
import { IUser } from 'models/user'
import { EstimateType } from 'views/CalculateEmissions'
import { PaginatedInvoices } from 'views/Settings/Billing/Billing'

export type DapiErrors = DapiErrorsResponse
export type DapiOrder = PaginatedOrdersWithAccountInfo['data'][number]

let dapi = bootstrapAxiosClient(`${process.env.REACT_APP_DAPI_URL}`)
let dapiNoInterceptors = bootstrapAxiosClientNoInterceptors(`${process.env.REACT_APP_DAPI_URL}`)

export const bootstrapDapi = () => {
    dapi = bootstrapAxiosClient(`${process.env.REACT_APP_DAPI_URL}`)
    dapiNoInterceptors = bootstrapAxiosClientNoInterceptors(`${process.env.REACT_APP_DAPI_URL}`)
}

export const resetPassword = async (data: { password: string; token: string }): Promise<any> =>
    (await dapi.post(`/users/reset-password`, data)).data

export const verifyEmail = async (token: string) =>
    (await dapi.get(`/users/email-verification/${token}`)).data

export const getUserData = async () => (await dapi.get(`/users`)).data

interface IFormData {
    email: string
    password: string
}

type SignupArg = {
    email?: string
    firstname: string
    lastname: string
    marketingConsent?: boolean
    password?: string
    accessToken?: string
    currency?: string
    organisationName?: string
    invitationId?: string
}

export const signup = async (
    signupPayload: SignupArg,
): Promise<{
    user: IUser
    account: IAccount
    token?: string
    docsPublishableKey: string
}> => (await dapi.post(`/v1/users`, signupPayload)).data

export const getAccounts = async ({
    after,
    limit,
}: {
    after?: string
    limit?: number
}): Promise<IPaginationResult<IAccount>> => {
    return (
        await dapi.get(`v1/users/accounts`, {
            params: {
                ...(after && { after }),
                ...(limit && { limit }),
            },
        })
    ).data
}

export const getTeamData = async (): Promise<IUser[]> => (await dapi.get(`/accounts/team`)).data

export const getApiKeys = async (): Promise<IApiKey[]> => (await dapi.get(`/api-keys`)).data

export const rotateApiKeys = async (apiKeyId: string): Promise<IApiKeyWithSecret> => {
    return (await dapi.put(`/api-keys/${apiKeyId}/rotate`)).data
}

export const updateApiKeyStatus = async (apiKeyId: string, status: ApiKeyStatus): Promise<void> =>
    (await dapi.patch(`/api-keys/${apiKeyId}`, { status })).data

export const createApiKey = async (data: CreateApiKeyRequest): Promise<string> =>
    (await dapi.post(`/v1/api-keys`, data)).data

export const deleteApiKey = async (apiKeyId: string): Promise<void> =>
    (await dapi.delete(`/api-keys/${apiKeyId}`)).data

export const googleAuthorisation = async (data: {
    redirectUrl: string
}): Promise<{ authorisationUrl: string }> =>
    (await dapi.post(`/v1/users/google-authorisation`, data)).data

export type GoogleSSOUserProfile = {
    accessToken: string
    email: string
    firstname?: string
    lastname?: string
    avatar?: string
}

export const googleVerifyAuthorisationCode = async (data: {
    code: string
    redirectUrl: string
}): Promise<GoogleSSOUserProfile> =>
    (await dapi.post(`/v1/users/google-verify-authorisation-code`, data)).data

type LoginArg = {
    email?: string
    password?: string
    accessToken?: string
}

export const login = async (
    data: LoginArg,
): Promise<{
    user: IUser
    account: IAccount
    isAdmin: boolean
    token?: string
    docsPublishableKey: string
}> => (await dapi.post(`/v1/users/login`, data)).data

export const logout = async (): Promise<void> => (await dapi.post(`/users/logout`)).data

export const getActivity = async (after?: string): Promise<IPaginationResult<IActivity>> =>
    (await dapi.get(`/activity${after ? `?after=${after}` : ''}`)).data

export const requestPasswordReset = async (email: string): Promise<IFormData> =>
    (await dapi.post(`/users/forgot-password`, { email })).data

export const getUserAccounts = async (): Promise<IAccount[]> =>
    (await dapi.get(`users/accounts`)).data

export const setUserPrimaryAccountId = async (primaryAccountId: number | string): Promise<void> =>
    (await dapi.post(`/users/primary-account`, { id: primaryAccountId })).data

export const getUserSettings = async (): Promise<any> => (await dapi.get(`/users/settings`)).data

export const setUserSettings = async (settings: object): Promise<any> =>
    (await dapi.put('/users/settings', settings)).data

export const getPaymentMethods = async (): Promise<PaymentMethods> =>
    (await dapi.get(`/v1/payment-methods`)).data

export const deletePaymentMethod = async (stripeId: string): Promise<void> =>
    (await dapi.delete(`/v1/payment-methods/${stripeId}`)).data

export const createPaymentMethodInitiation = async (): Promise<PaymentMethodInitiation> =>
    (await dapi.post(`/v1/payment-methods`)).data

export const uploadLogo = (
    fd: FormData,
): Promise<{
    data: {
        url: string
    }
}> =>
    dapiNoInterceptors.post(`/accounts/logo`, fd, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

export const getUserOrganisations = async (): Promise<Organisation[]> =>
    (await dapi.get(`/v1/organisations`)).data
export const getOrganisation = async (id: string): Promise<Organisation> =>
    (await dapi.get(`/v1/organisations/${id}`)).data
export const getOrganisationUsers = async (id: string): Promise<OrganisationUsers> =>
    (await dapi.get(`/v1/organisations/${id}/users`)).data
export const patchOrganisation = async (
    id: string,
    organisation: PatchOrganisationRequest,
): Promise<Organisation> => (await dapi.patch(`/v1/organisations/${id}`, organisation)).data
export const uploadOrganisationLogo = (
    id: string,
    fd: FormData,
): Promise<{
    data: {
        url: string
    }
}> =>
    dapiNoInterceptors.post(`/v1/organisations/${id}/logo`, fd, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

export const promoteOrganisationAdmin = async (
    organisationId: string,
    userId: string,
): Promise<any> => (await dapi.put(`v1/organisations/${organisationId}/users/${userId}/admin`)).data
export const demoteOrganisationAdmin = async (
    organisationId: string,
    userId: string,
): Promise<any> =>
    (await dapi.delete(`v1/organisations/${organisationId}/users/${userId}/admin`)).data
export const removeFromOrganisation = async (
    organisationId: string,
    userId: string,
): Promise<any> => (await dapi.delete(`v1/organisations/${organisationId}/users/${userId}`)).data
export const inviteToOrganisation = async (organisationId: string, email: string): Promise<any> =>
    (await dapi.post(`v1/organisations/${organisationId}/invite`, { email })).data
export const removeInviteFromOrganisation = async (
    organisationId: string,
    inviteId: string,
): Promise<any> => (await dapi.delete(`v1/organisations/${organisationId}/invite/${inviteId}`)).data

export const getOrders = async ({
    after,
    limit,
    accountId,
    status,
    from,
    through,
}: IPaginationQueryParams & {
    accountId?: string[]
    status?: OrderStatus[]
    from?: string
    through?: string
} = {}): Promise<PaginatedOrdersWithAccountInfo> => {
    // Need to explicitly encode from and through, axios doesn't work
    const queryString = []
    if (from) {
        queryString.push(`from=${encodeURIComponent(from)}`)
    }
    if (through) {
        queryString.push(`through=${encodeURIComponent(through)}`)
    }
    return (
        await dapi.get(`v1/orders?${queryString.join('&')}`, {
            params: {
                ...(after && { after }),
                ...(limit && { limit }),
                ...(accountId && { accountId }),
                ...(status && { status }),
            },
        })
    ).data
}

export const getAccountsById = async ({
    accountId,
}: {
    accountId: string[]
}): Promise<AccountMini[]> => {
    return (
        await dapi.get(`v1/accounts`, {
            params: {
                ...(accountId.length && { accountId }),
            },
        })
    ).data
}

export const getRecentlyUsedAccounts = async ({
    name,
    type,
}: {
    name?: string
    type?: AccountType
}): Promise<AccountMini[]> => {
    return (
        await dapi.get(`v1/accounts/recently-used`, {
            params: {
                ...(name && { name }),
                ...(type && { type }),
            },
        })
    ).data
}

export const getEstimates = async ({
    after,
    limit,
    accountId,
    from,
    through,
    type,
}: IPaginationQueryParams & {
    accountId?: string[]
    type?: EstimateType[]
    from?: string
    through?: string
} = {}): Promise<PaginatedEstimatesWithAccountInfo> => {
    // Need to explicitly encode from and through, axios doesn't work
    const queryString = []
    if (from) {
        queryString.push(`from=${encodeURIComponent(from)}`)
    }
    if (through) {
        queryString.push(`through=${encodeURIComponent(through)}`)
    }
    return (
        await dapi.get(`v1/estimates?${queryString.join('&')}`, {
            params: {
                ...(after && { after }),
                ...(limit && { limit }),
                ...(accountId && { accountId }),
                ...(type && { type }),
            },
        })
    ).data
}

export const getInvoices = async ({
    after,
    limit,
}: IPaginationQueryParams): Promise<PaginatedInvoices> => {
    return await dapi.get(`/v1/invoices`, {
        params: {
            ...(after && { after }),
            ...(limit && { limit }),
        },
    })
}

export const setupPaymentIntent = async (): Promise<CreatePaymentMethodResponse> =>
    (await dapi.post(`v1/payment-methods/setup-intent`)).data

export const listEmissionFactors = async ({
    limit,
    after,
    name,
    source,
    publicationYear,
    region,
    unit,
    category,
}: IPaginationQueryParams & {
    name?: string
    source?: EmissionFactor['source'][]
    publicationYear?: number[]
    region?: string[]
    unit?: EmissionFactorLogicalUnit
    category?: string[]
}): Promise<PaginatedEmissionFactors> => {
    return (
        await dapi.get('/v1/emission-factors', {
            params: {
                limit,
                after,
                name,
                source,
                publicationYear,
                region,
                unit,
                category,
            },
        })
    ).data
}

export const uploadLogisticsSheet = (
    fd: FormData,
): Promise<{
    data: {
        url: string
    }
}> =>
    dapiNoInterceptors.post(`/v1/logistics-sheets`, fd, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })

export const getLogisticsSheets = async ({
    after,
    limit,
}: {
    after?: string
    limit?: number
}): Promise<IPaginationResult<LogisticsSheet>> => {
    return (
        await dapi.get(`v1/logistics-sheets`, {
            params: {
                ...(after && { after }),
                ...(limit && { limit }),
            },
        })
    ).data
}

export const downloadLogisticsSheet = async (id: string): Promise<string> => {
    return (await dapi.get(`v1/logistics-sheets/${id}/download`)).data
}

export const createProposal = async (data: ProposalRequest): Promise<ProposalResponse> =>
    (await dapi.post(`/v1/proposals`, data)).data

export const getProposalById = async (id: string): Promise<ProposalResponse> =>
    (await dapi.get(`v1/proposals/${id}`)).data

export const getPublicProposalById = async (id: string): Promise<PublicProposalResponse> =>
    (await dapi.get(`v1/proposals/public/${id}`)).data

export const getProposals = async ({
    after,
    limit,
}: {
    after?: string
    limit?: number
}): Promise<PaginatedProposals> => {
    return (
        await dapi.get(`v1/proposals`, {
            params: {
                ...(after && { after }),
                ...(limit && { limit }),
            },
        })
    ).data
}

export const getCumulativeAnalyticsPerBundle = async ({
    from,
    through,
    accountIds,
}: {
    from?: string
    through?: string
    accountIds?: string[]
}): Promise<CumulativeBundleAnalytics> => {
    return (
        await dapi.get(`v1/analytics/cumulative-per-bundle`, {
            params: {
                ...(from && { from: encodeURIComponent(from) }),
                ...(through && { through: encodeURIComponent(through) }),
                ...(accountIds && { accountId: accountIds }),
            },
        })
    ).data
}

export const getShippingEmissionsAnalytics = async ({
    from,
    through,
    interval,
    accountIds,
}: {
    from?: string
    through?: string
    interval?: string
    accountIds?: string[]
}): Promise<ShippingEmissionTimeseries> => {
    return (
        await dapi.get(`v1/analytics/shipping/estimates/by-method`, {
            params: {
                ...(from && { from: encodeURIComponent(from) }),
                ...(through && { through: encodeURIComponent(through) }),
                ...(interval && { interval }),
                ...(accountIds && { accountId: accountIds }),
            },
        })
    ).data
}

export const getEmissionCalculationMetrics = async ({
    from,
    through,
    interval,
    accountIds,
}: {
    from?: string
    through?: string
    interval?: string
    accountIds?: string[]
}): Promise<EmissionCalculationMetrics> => {
    return (
        await dapi.get(`v1/analytics/emission-calculations`, {
            params: {
                ...(from && { from: encodeURIComponent(from) }),
                ...(through && { through: encodeURIComponent(through) }),
                ...(interval && { interval }),
                ...(accountIds && { accountId: accountIds }),
            },
        })
    ).data
}

export const getAggregatedAnalyticsByProperty = async ({
    from,
    through,
    accountIds,
}: {
    from?: string
    through?: string
    accountIds?: string[]
}): Promise<AggregatedAnalyticsByProperty> => {
    return (
        await dapi.get(`v1/analytics/aggregated-by-property`, {
            params: {
                ...(from && { from: encodeURIComponent(from) }),
                ...(through && { through: encodeURIComponent(through) }),
                ...(accountIds && { accountId: accountIds }),
            },
        })
    ).data
}

export const getMetrics = async ({
    from,
    through,
    accountIds,
}: {
    from?: string
    through?: string
    accountIds?: string[]
}): Promise<AnalyticsMetrics> => {
    return (
        await dapi.get(`v1/analytics/metrics`, {
            params: {
                ...(from && { from: encodeURIComponent(from) }),
                ...(through && { through: encodeURIComponent(through) }),
                ...(accountIds && { accountId: accountIds }),
            },
        })
    ).data
}

export const getEmissionFactorVersions = async (
    id: string,
): Promise<{ versions: EmissionFactor[]; identifiedIndex: number }> => {
    return (await dapi.get(`v1/emission-factors/${id}/versions`)).data
}
