import axios from 'axios'
import { parse } from 'csv-parse/sync'
import { useQuery } from 'react-query'

const useCSVFromUrl = <T>(url: string) => {
    const { isLoading: loading, data } = useQuery<T[]>(
        url,
        () =>
            axios.get(url).then(({ data }) =>
                parse(data, {
                    columns: true,
                    skip_empty_lines: true,
                    relax_column_count: true,
                }),
            ),
        {
            enabled: true,
            refetchOnWindowFocus: false,
        },
    )

    return {
        loading,
        data,
    }
}

export default useCSVFromUrl
