// Disable sorting lint rule for the following import because Prism should be imported first before importing rest of the files related to Prism
// eslint-disable-next-line
import Prism from 'prismjs'

import '../css/prism-lune.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
// import languages that you want to support
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-markdown'
import 'prismjs/components/prism-fortran'

import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import type { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { LuneTheme } from '../theme'
import Button from './Button'
import Dropdown, { DropdownItem } from './Dropdown'
import Tile from './Tile'

export interface ISnippetItemProps {
    toCopy?: string
    language?: string
    lineNumbers?: boolean
    label?: string
    children: React.ReactNode
}

export const SnippetItem: FC<ISnippetItemProps> = ({ language, lineNumbers, children }) => {
    useEffect(() => {
        Prism.highlightAll()
    }, [language])

    return (
        <>
            {language ? (
                <pre
                    className={lineNumbers ? `line-numbers` : ``}
                    style={{
                        padding: '0px',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <code className={`language-${language}`}>{children}</code>
                </pre>
            ) : (
                children
            )}
        </>
    )
}

export interface ISnippetProps {
    header: string
    sx?: SxProps
    children: React.ReactElement<ISnippetItemProps> | React.ReactElement<ISnippetItemProps>[]
    onChange?: (i: number) => void
}

const Snippet: FC<ISnippetProps> = ({ header, onChange, children, sx, ...rest }) => {
    const { typography, palette, spacing } = LuneTheme
    const numChildren = React.Children.count(children)
    const multiChild = numChildren > 1

    if (numChildren === 0) {
        throw new Error('At least one child is required')
    }

    // toArray returns a Array<Exclude<ReactNode, boolean | null | undefined>>
    const [selected, setSelected] = useState<React.ReactElement<ISnippetItemProps>>(
        React.Children.toArray(children)[0] as React.ReactElement<ISnippetItemProps>,
    )

    const StyledSnippet = useMemo(
        () =>
            styled(Stack)(
                LuneTheme.unstable_sx({
                    height: '100%',
                    width: '100%',
                    borderRadius: '8px',
                    border: `solid 1px ${palette.Grey200}`,
                    backgroundColor: palette.Grey50,
                    '& pre': {
                        margin: '0px',
                        padding: '0px',
                    },
                    '& .MuiTypography-root[variant="body1"]': {
                        ...typography.body1,
                        color: palette.Grey600,
                    },
                    '& .MuiTypography-root[variant="body3"]': {
                        ...typography.body3,
                        color: palette.Grey600,
                    },
                    '& .MuiButton-root': {
                        // drowndown label
                        backgroundColor: palette.Grey200,
                        '& .MuiTypography-root': {
                            ...typography.body2,
                        },
                    },
                }),
            ),
        [],
    )

    const onDropdownChange = useCallback(
        (i: number) => {
            setSelected(
                React.Children.toArray(children)[i] as React.ReactElement<ISnippetItemProps>,
            )
            if (onChange) {
                onChange(i)
            }
        },
        [selected],
    )

    const { toCopy, label } = selected.props

    return (
        <StyledSnippet sx={sx} {...rest}>
            <Tile
                sx={{
                    backgroundColor: palette.Grey200,
                    minHeight: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                }}
                rightSide={
                    multiChild ? (
                        <Dropdown
                            buttonLabel={label ?? ''}
                            compact
                            menuAutoWidth
                            sx={{ minWidth: 'unset' }}
                        >
                            {React.Children.map(children, ({ props: { label } }, i: number) => (
                                <DropdownItem key={i} onClick={() => onDropdownChange(i)}>
                                    {label ?? ''}
                                </DropdownItem>
                            ))}
                        </Dropdown>
                    ) : undefined
                }
                button={
                    toCopy ? (
                        <CopyToClipboard text={toCopy}>
                            <Button
                                variant="text"
                                iconButton
                                leftIcon={
                                    <ContentCopyIcon sx={{ width: '20px', height: '20px' }} />
                                }
                            />
                        </CopyToClipboard>
                    ) : undefined
                }
            >
                <Typography
                    variant="button"
                    color="Grey900"
                    sx={{
                        ...typography.button,
                        color: palette.Grey900,
                        marginLeft: spacing(1),
                    }}
                >
                    {header}
                </Typography>
            </Tile>
            <Box
                sx={{
                    overflow: 'auto',
                    padding: '16px',
                    display: 'flex',
                    height: '100%',
                    backgroundColor: palette.Grey50,
                    '&:first-child': {
                        margin: '0px',
                    },
                    fontSize: '13px',
                    fontWeight: '400',
                }}
            >
                {selected}
            </Box>
        </StyledSnippet>
    )
}

export default Snippet
