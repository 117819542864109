import { InfiniteData, useInfiniteQuery } from 'react-query'

import { getLogisticsSheets } from 'endpoints/dapi'
import { LogisticsSheet } from 'models/openDapi'
import { queryKeys } from 'queryKeys'

type PaginatedLogisticsSheets = { hasMore: boolean; data: LogisticsSheet[] }

const useLogisticsSheets = (): {
    paginatedLogisticsSheets: InfiniteData<PaginatedLogisticsSheets> | undefined
    loading: boolean
    fetchNextPage: () => void
    refetch: () => void
    hasNextPage: boolean | undefined
} => {
    const {
        data: paginatedLogisticsSheets,
        isLoading,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery<PaginatedLogisticsSheets>({
        queryKey: queryKeys.GET_LOGISTICS_SHEETS,
        queryFn: async ({ pageParam = { cursor: undefined } }) => {
            return getLogisticsSheets({ after: pageParam.cursor })
        },
        getNextPageParam: (lastPage) => {
            return lastPage.hasMore
                ? { cursor: lastPage.data[lastPage.data.length - 1].id }
                : undefined
        },
        refetchOnMount: true,
    })

    return {
        paginatedLogisticsSheets,
        loading: isLoading,
        fetchNextPage,
        refetch,
        hasNextPage,
    }
}

export default useLogisticsSheets
