import {
    Address,
    AirportCode,
    EmissionFactorDenominatorUnit,
    EmissionFactorWithGasEmissions,
    GeographicCoordinates,
    Locode,
    LogisticsSiteMethod,
    Shipment,
    ShippingMethod,
    ShippingRoute,
    ShippingSourceDestination,
} from '@lune-climate/lune'

import { CalculateEmissionsIcons } from '../calculateEmissionsIcons'
import { ResultTileProps } from '../components'

import {
    convertDDCoordinatesToDMS,
    formatAddress,
    FORMATTED_CARRIER_NAMES,
    getEmptyRunsFactorLabel,
    getLoadFactorLabel,
    getLoadLabel,
    getRoadEmissionStandard,
    getRoadGrandientAndSituationLabel,
    getRoadVehicleName,
    getShippingMethodLabel,
    getTradeLaneLabel,
    isLogisticsSiteMethod,
    typeIcon,
} from './logisticsUtils'

export const buildLogisticsLabelExtension = (
    key: string,
    value: string | boolean | object,
): string | null => {
    switch (key) {
        case 'fuel': {
            return value as string
        }
        case 'load': {
            return getLoadLabel(value as string)
        }
        case 'tradeLane': {
            return getTradeLaneLabel(value as string)
        }
        case 'refrigerated': {
            if (value) {
                return 'refrigerated'
            }
            break
        }
        default:
            return null
    }
    return null
}

const formatLabelExtension = (extensions: string[]): string => {
    return extensions.length ? `, ${extensions.join(', ')}` : ''
}

// eslint-disable-next-line complexity
export const buildLogisticsTypeTiles = (
    value: ShippingMethod | LogisticsSiteMethod,
    emissionFactor: EmissionFactorWithGasEmissions,
): ResultTileProps[] => {
    const labelExtension = Object.entries(value)
        .map(([key, value]) => buildLogisticsLabelExtension(key, value))
        .filter((e): e is Exclude<typeof e, null> => e !== null)

    if (value instanceof Object && 'flightNumber' in value) {
        return [
            {
                label: <>Flight number {value.flightNumber}</>,
                icon: CalculateEmissionsIcons.flights,
            },
            {
                // TODO: remove when BE is released
                label: <>Departed on {(value as any).departureOn}</>,
                icon: CalculateEmissionsIcons.calendar,
            },
        ]
    }
    if (value instanceof Object && 'vesselImoNumber' in value) {
        return [
            {
                label: <>IMO {value.vesselImoNumber}</>,
                icon: CalculateEmissionsIcons.number,
            },
        ]
    } else if (value instanceof Object && 'vesselName' in value) {
        return [
            {
                label: <>{value.vesselName}</>,
                icon: CalculateEmissionsIcons.ship,
            },
        ]
    } else if (value instanceof Object && 'vesselInference' in value) {
        return [
            {
                label: <>{FORMATTED_CARRIER_NAMES[value.vesselInference.carrierName]}</>,
                icon: CalculateEmissionsIcons.ship,
            },
        ]
    } else if (value instanceof Object && 'vesselType' in value) {
        return [
            {
                label: (
                    <>
                        {getShippingMethodLabel(value.vesselType) +
                            formatLabelExtension(labelExtension)}
                    </>
                ),
                icon: typeIcon(value.vesselType) ?? null,
            },
        ]
    } else if (value instanceof Object && 'vehicleType' in value && 'fuel' in value) {
        const loadFactors = [
            ...(value.loadFactor ? [getLoadFactorLabel(value.loadFactor)] : []),
            ...(value.emptyRunsFactor ? [getEmptyRunsFactorLabel(value.emptyRunsFactor)] : []),
        ]
        return [
            {
                label: (
                    <>
                        {getRoadVehicleName(value.vehicleType)}
                        {value.emissionStandard
                            ? `, ${getRoadEmissionStandard(value.emissionStandard, { shortForm: true })}`
                            : null}
                    </>
                ),
                icon: CalculateEmissionsIcons.truck,
            },
            {
                label: <>{emissionFactor.name}</>,
                icon: CalculateEmissionsIcons.fuel,
            },
            ...(loadFactors.length > 0
                ? [
                      {
                          label: <>{loadFactors.join(', ')}</>,
                          icon: CalculateEmissionsIcons.package,
                      },
                  ]
                : []),
            ...(value.gradient || value.situation
                ? [
                      {
                          label: (
                              <>
                                  {getRoadGrandientAndSituationLabel(
                                      value.gradient,
                                      value.situation,
                                  )}
                              </>
                          ),
                          icon: CalculateEmissionsIcons.road, // TODO: fix incorrect icon, ask Ale
                      },
                  ]
                : []),
        ]
    } else if (isLogisticsSiteMethod(value)) {
        return [
            {
                label: <>{getShippingMethodLabel(value)}</>,
                icon: CalculateEmissionsIcons.warehouse,
            },
        ]
    } else {
        return [
            {
                label: <>{getShippingMethodLabel(value) + formatLabelExtension(labelExtension)}</>,
                icon: typeIcon(value) ?? null,
            },
        ]
    }
}

const getSourceOrDestination = (
    location: Locode | GeographicCoordinates | AirportCode | Address,
    label: string,
): ResultTileProps => {
    // Locode
    if ('locode' in location) {
        const icon = CalculateEmissionsIcons.ship
        return {
            icon,
            label: `${label} ${location.locode}`,
        }
    }

    // GeographicCoordinates
    if ('lat' in location) {
        const icon = CalculateEmissionsIcons.location
        return {
            icon,
            label: `${label} ${convertDDCoordinatesToDMS(
                location.lat,
            )}, ${convertDDCoordinatesToDMS(location.lon, true)}`,
        }
    }

    // AirportCode
    if ('code' in location) {
        const icon = CalculateEmissionsIcons.plane
        return {
            icon,
            label: `${label} ${location.code}`,
        }
    }

    // Address
    return {
        icon: CalculateEmissionsIcons.address,
        label: `${label} ${formatAddress(location)}`,
    }
}

const getShippingSourceDestinationTile = (route: ShippingSourceDestination): ResultTileProps[] => {
    const source: ResultTileProps = getSourceOrDestination(route.source, 'From')
    const destination: ResultTileProps = getSourceOrDestination(route.destination, 'To')
    return [source, destination]
}

export const buildRouteTiles = (route: ShippingRoute) => {
    if ('amount' in route) {
        // shipping distance
        return [
            {
                icon: CalculateEmissionsIcons.route,
                label: `${route.amount} ${route.unit}`,
            },
        ]
    } else {
        // shipping source and destination
        return getShippingSourceDestinationTile(route)
    }
}

export const loadLabel = (
    shipment: Shipment,
    emissionFactorDenominatorUnit: EmissionFactorDenominatorUnit,
): string => {
    // * if both mass and containers are present => check denominatorUnit to determine whether containers should be shown
    if ('mass' in shipment && 'containers' in shipment) {
        return ['TEU', 'TEU*km'].includes(emissionFactorDenominatorUnit)
            ? `${shipment.containers} TEU`
            : `${shipment.mass!.amount} ${shipment.mass!.unit}`
    }

    return 'containers' in shipment
        ? `${shipment.containers} TEU`
        : `${shipment.mass.amount} ${shipment.mass.unit}`
}

export const buildLoadTiles = (
    shipment: Shipment,
    emissionFactorDenominatorUnit: EmissionFactorDenominatorUnit,
) => {
    return [
        {
            label: <>{loadLabel(shipment, emissionFactorDenominatorUnit)}</>,
            icon: CalculateEmissionsIcons.load,
        },
    ]
}
