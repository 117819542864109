import { TransactionEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponse } from '@lune-climate/lune/esm/core/SuccessResponse'
import {
    formatToCurrency,
    getCountryNameByThreeLetterCode,
    ResultLabeledSectionAndTiles,
    ResultTileProps,
} from '@lune-fe/lune-components-lib'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
import FastfoodIcon from '@mui/icons-material/FastfoodOutlined'
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined'
import PublicIcon from '@mui/icons-material/PublicOutlined'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined'
import { startCase } from 'lodash'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { notify } from 'bugsnag'
import Divider from 'components/Divider'
import useCSVFromUrl from 'hooks/useCSVFromUrl'
import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import EveryDayPurchaseExplanationSection from 'views/CalculateEmissions/EverydayPurchases/EveryDayPurchaseExplanationSection'
import {
    Category,
    CATEGORY_CODES_URL,
} from 'views/CalculateEmissions/EverydayPurchases/EverydayPurchases'
import { EstimateTypeEnum, ResultsWrapper } from 'views/CalculateEmissions/index'
import RequestAndResponse from 'views/CalculateEmissions/Shared/RequestAndResponse'

const EverydayPurchasesResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { data: categories } = useCSVFromUrl<Category>(CATEGORY_CODES_URL)
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { id } = useParams<{ id: string }>()
    const [estimate, setEstimate] = useState<SuccessResponse<TransactionEmissionEstimate>>()
    const luneClient = useLuneClient()
    const { palette } = LuneTheme

    const tiles: ResultTileProps[] = useMemo(() => {
        if (!estimate) {
            return []
        }

        const countryCode = estimate.request.merchant.countryCode
        const items = []

        if ('searchTerm' in estimate.request.merchant) {
            const searchTerm = estimate.request.merchant.searchTerm
            items.push({
                label: `Search term: ${searchTerm}`,
                icon: <BorderColorOutlinedIcon sx={{ color: palette.Grey900 }} />,
            })
        }

        if ('categoryCode' in estimate.request.merchant) {
            const category = estimate.request.merchant.categoryCode
            items.push({
                label: categories?.find((c) => c.mcc === category)?.description || category,
                icon: <ShoppingCartIcon sx={{ color: palette.Grey900 }} />,
            })
        }

        if ('category' in estimate.request.merchant) {
            const category = estimate.request.merchant.category
            items.push({
                label: `Category: ${category}`,
                icon: <CategoryOutlinedIcon sx={{ color: palette.Grey900 }} />,
            })
        }

        if (estimate.request.diet) {
            items.push({
                label: startCase(estimate.request.diet),
                icon: <FastfoodIcon sx={{ color: palette.Grey900 }} />,
            })
        }

        return [
            ...items,
            {
                label:
                    getCountryNameByThreeLetterCode(countryCode, () =>
                        notify(new Error(`Could not find country with code ${countryCode}`)),
                    ) ?? countryCode,
                icon: <PublicIcon sx={{ color: palette.Grey900 }} />,
            },
            {
                label: formatToCurrency(
                    estimate.request.value.value,
                    estimate.request.value.currency,
                ),
                icon: <PaymentsIcon sx={{ color: palette.Grey900 }} />,
            },
        ]
    }, [estimate, palette.Grey900, categories])

    useEffect(() => {
        if (id) {
            luneClient
                .getTransactionEstimate(id)
                .then((res) => {
                    if (res.isOk()) {
                        const e = res.value
                        setEstimate(e)
                    }
                })
                .catch(() => snackbar(SnackbarMessages.GENERIC_ERROR))
        }
    }, [id, snackbar, luneClient])

    return (
        <ResultsWrapper
            scrollRef={scrollRef}
            title={estimate?.request.name}
            emissionType={EstimateTypeEnum.TRANSACTION}
            calculatedAmount={estimate?.mass.amount!}
        >
            {estimate?.request && estimate.emissionFactor && (
                <>
                    <Divider />
                    <EveryDayPurchaseExplanationSection
                        estimateRequest={estimate.request}
                        emissions={estimate.mass}
                        dietFactor={estimate.dietFactor}
                        exchangeRate={estimate.exchangeRate}
                        exchangeRateDate={estimate.exchangeRateDate}
                        emissionFactor={estimate.emissionFactor}
                    />
                    <Divider />
                </>
            )}
            <ResultLabeledSectionAndTiles label={`Your input`} tiles={tiles} />
            {estimate && (
                <>
                    <Divider />
                    <RequestAndResponse
                        path={`/v1/estimates/transactions`}
                        request={estimate._meta.response.request}
                        response={estimate._meta.response}
                        idempotencyKey={
                            (estimate._meta.response as { idempotency_key?: string })
                                .idempotency_key
                        }
                        metadata={
                            (estimate._meta.response as { metadata?: Record<string, string> })
                                .metadata
                        }
                    />
                </>
            )}
        </ResultsWrapper>
    )
}

export default EverydayPurchasesResults
