import { Shipment, ShippingRoute } from '@lune-climate/lune'
import {
    buildLoadTiles,
    buildLogisticsTypeTiles,
    buildRouteTiles,
    EmissionsExplanationSection,
    getFormattedAmount,
    InputsWrapper,
    LegMethodIcon,
    ResultLabeledSectionAndTiles,
} from '@lune-fe/lune-components-lib'
import { ButtonGroup } from '@lune-fe/lune-ui-lib'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'

import Divider from 'components/Divider'
import { Leg } from 'views/CalculateEmissions/MultiLegLogistics/MultiLegLogisticsResults'
import RequestAndResponse from 'views/CalculateEmissions/Shared/RequestAndResponse'

const MultiLegEstimate = ({
    legs,
    load,
    meta,
    requestRoute,
}: {
    legs: Leg[]
    load: Shipment
    meta?: any
    requestRoute?: ShippingRoute
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState<string>('1')

    const activeLeg = useMemo(() => {
        if (activeTabIndex && legs.length) {
            return legs[Number(activeTabIndex) - 1]
        }
        return null
    }, [activeTabIndex, legs])

    const activeLegFormattedAmount = useMemo(() => {
        if (activeTabIndex && legs.length) {
            const leg = legs[Number(activeTabIndex) - 1]
            return {
                amountAsNumber: getFormattedAmount(leg.mass.amount).amountAsNumber,
                amountUnit: getFormattedAmount(leg.mass.amount).amountUnit,
            }
        }
        return null
    }, [activeTabIndex, legs])

    return (
        <>
            <ButtonGroup
                items={legs.map((leg, index) => {
                    const legNumber = index + 1
                    return {
                        label: (
                            <>
                                <LegMethodIcon method={leg.method} /> Leg {legNumber}
                            </>
                        ),
                        value: `${legNumber}`,
                        dataTestId: `leg-btn-${legNumber}`,
                    }
                })}
                onChange={(active) => {
                    setActiveTabIndex(active)
                }}
                value={activeTabIndex}
            />
            {activeLeg && activeLegFormattedAmount && legs.length && (
                <>
                    <ResultLabeledSectionAndTiles
                        label={'Emissions for this leg'}
                        dataTestId={'leg-emissions-amount'}
                        tiles={[
                            {
                                icon: <Co2OutlinedIcon />,
                                label: `${activeLegFormattedAmount.amountAsNumber.toFixed(
                                    2,
                                )} ${activeLegFormattedAmount.amountUnit}CO₂e`,
                            },
                        ]}
                    />
                    <Divider />
                    <EmissionsExplanationSection
                        emissions={activeLeg.mass}
                        emissionFactor={activeLeg.emissionFactor}
                        methodology={activeLeg.methodology}
                        distance={activeLeg.distance}
                        adjustedDistance={activeLeg.adjustedDistance}
                        load={load}
                        distanceCalculationMethod={activeLeg.distanceCalculationMethod}
                        distanceCalculationDetails={activeLeg.distanceCalculationDetails}
                        loadConversionMethod={activeLeg.shipmentConversionMethod}
                        convertedLoad={activeLeg.convertedShipment}
                        requestRoute={activeLeg.route ?? null}
                        requestMethod={activeLeg.method}
                        responseRoute={activeLeg.responseRoute}
                        methodologyDetails={activeLeg.methodologyDetails}
                        vesselInference={activeLeg.vesselInferenceDetails ?? null}
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    />
                    <Divider />
                    <Box>
                        <InputsWrapper>
                            <ResultLabeledSectionAndTiles
                                tiles={[
                                    ...buildLogisticsTypeTiles(
                                        activeLeg.method,
                                        activeLeg.emissionFactor,
                                    ),
                                    ...(requestRoute ? buildRouteTiles(requestRoute) : []),
                                    ...(activeLeg.route && !requestRoute
                                        ? buildRouteTiles(activeLeg.route)
                                        : []),
                                    ...buildLoadTiles(
                                        load,
                                        activeLeg.emissionFactor.denominatorUnit,
                                    ),
                                ]}
                                dataTestId={'leg-shipping-method'}
                            />
                        </InputsWrapper>
                    </Box>
                    <Divider />
                    <RequestAndResponse
                        path={`/v1/estimates/shipping/multi-leg`}
                        request={meta.response.request}
                        response={meta.response}
                        idempotencyKey={
                            (meta.response as { idempotency_key?: string }).idempotency_key
                        }
                        metadata={
                            (
                                meta.response as {
                                    metadata?: Record<string, string>
                                }
                            ).metadata
                        }
                    />
                </>
            )}
        </>
    )
}

export default MultiLegEstimate
