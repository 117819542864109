import { CalculationResults, formatNumbers } from '@lune-fe/lune-components-lib'
import { Button, LoadingWrapper, MainLayoutContainer } from '@lune-fe/lune-ui-lib'
import CloseIcon from '@mui/icons-material/Close'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { ReactNode, RefObject, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { EstimateTypeEnum, EstimateTypeName } from 'views/CalculateEmissions'

const ResultsWrapper = ({
    children,
    emissionType,
    title,
    subtitle,
    calculatedAmount,
    scrollRef,
    loading,
}: {
    children: ReactNode
    emissionType: EstimateTypeEnum
    title?: string
    subtitle?: string
    calculatedAmount: string
    scrollRef: RefObject<HTMLDivElement>
    loading?: boolean
}) => {
    const allQueryParams = useLocation().search

    const navigate = useNavigate()
    useEffect(() => {
        scrollRef.current?.scrollTo({ top: 0 })
    }, [scrollRef])
    return (
        <LoadingWrapper loading={loading} sx={{ height: 1 }}>
            {!loading && (
                <MainLayoutContainer
                    headerComponent={
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                iconButton
                                leftIcon={<CloseIcon />}
                                variant={'text'}
                                sx={{ ml: -1.5 }}
                                onClick={() => navigate(`/calculate-emissions${allQueryParams}`)}
                                data-testid={'logistics-results-close-button'}
                            />
                            <Button
                                variant={'contained'}
                                leftIcon={<CloudDownloadOutlinedIcon />}
                                onClick={() =>
                                    navigate(`/order?amount=${formatNumbers(calculatedAmount, 2)}`)
                                }
                            >
                                Offset Now
                            </Button>
                        </Box>
                    }
                >
                    <CalculationResults
                        sx={{ mb: 9 }}
                        title={title || EstimateTypeName(emissionType)}
                        amount={calculatedAmount || ''}
                        subtitle={subtitle}
                    />
                    <Stack direction={'column'} spacing={9}>
                        {children}
                    </Stack>
                </MainLayoutContainer>
            )}
        </LoadingWrapper>
    )
}

export default ResultsWrapper
