import { Bundle, ClientAccount as LuneClientAccount } from '@lune-climate/lune'
import { BackButton, LoadingWrapper, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AccountFormWrapper, { AccountFormKeys } from 'components/AccountForm/AccountFormWrapper'
import OnlyAPIClientsTooltip from 'components/OnlyAPIClientsTooltip'
import useClientAccounts from 'hooks/useClientAccounts'
import useIndividualAccountBundles from 'hooks/useIndividualAccountBundles'
import { CUSTOM_PORTFOLIO_ID } from 'views/Settings/BundlePicker/BundlePicker'

const ClientAccount = () => {
    const navigate = useNavigate()
    const { paginatedAccounts } = useClientAccounts()
    const [account, setAccount] = useState<LuneClientAccount>()
    const [bundleSelection, setBundleSelection] = useState<Record<string, number | string>>()
    const [bundles, setBundles] = useState<Bundle[]>()
    const { accountId } = useParams<{ accountId: string }>()
    const { loadBundles, loadAllBundles } = useIndividualAccountBundles()

    useEffect(() => {
        const account = (paginatedAccounts?.pages ?? [])[0].data.find((acc) => acc.id === accountId)

        if (!account) {
            return
        }

        setAccount(account)
        loadBundles(account.id).then((res) => {
            setBundleSelection(res)
        })
        loadAllBundles(account.id).then((bundles) => setBundles(bundles))
    }, [accountId, paginatedAccounts, loadBundles, loadAllBundles])

    return (
        <LoadingWrapper loading={!account} sx={{ height: 1 }}>
            <MainLayoutContainer
                headerComponent={
                    <>
                        <BackButton
                            sx={{ mb: 3, ml: -2 }}
                            onClick={() => navigate('/settings/client-accounts')}
                        />
                        <Text variant="h6" sx={{ marginBottom: 1 }}>
                            Client account
                        </Text>
                        <Text variant="h4" sx={{ marginBottom: 1 }}>
                            {account?.name}
                        </Text>
                        <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }}>
                            Changes to Client Accounts cannot be made via the Lune Dashboard.
                        </Text>
                        <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }}>
                            Only Client Account holders can make edits via the Lune API.
                        </Text>
                    </>
                }
            >
                <></>
                <LoadingWrapper loading={!bundleSelection}>
                    <AccountFormWrapper
                        // @ts-ignore
                        onSubmit={() => {}}
                        readOnly={true}
                        onSuccess={() => {}}
                        initialValues={{
                            [AccountFormKeys.NAME]: account?.name ?? '',
                            [AccountFormKeys.CURRENCY]: account?.currency ?? '',
                            [AccountFormKeys.BENEFICIARY]: account?.beneficiary ?? '',
                            [AccountFormKeys.BUNDLE_SETTINGS]: {
                                customBundleSelection: bundleSelection!,
                                selectedBundlePortfolioId:
                                    account?.bundlePortfolioId ?? CUSTOM_PORTFOLIO_ID,
                            },
                        }}
                        bundles={bundles}
                        forbiddenTooltip={OnlyAPIClientsTooltip}
                    />
                </LoadingWrapper>
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default ClientAccount
