import { Button, Input, Modal, StandardModalHeader } from '@lune-fe/lune-ui-lib'
import { useState } from 'react'

import { useIsAuthorised } from 'hooks/useUserState'
import BeneficiaryTile from 'views/BuyOffsets/OrderSummary/Beneficiary/BeneficiaryTile'

const Beneficiary = ({
    title,
    subTitle,
    beneficiary,
    onSave,
}: {
    title: string
    subTitle: string
    beneficiary?: string
    onSave: (beneficiary: string) => void
}) => {
    const isAdmin = useIsAuthorised()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [value, setValue] = useState<string>(beneficiary || '')

    return (
        <>
            <BeneficiaryTile
                beneficiary={beneficiary}
                onEdit={() => setIsOpen(true)}
                isReadOnly={!isAdmin}
            />
            <Modal
                header={<StandardModalHeader title={title} subTitle={subTitle} />}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                    setValue(beneficiary || '')
                }}
                actions={
                    <>
                        <Button
                            variant={'outlined'}
                            wide
                            onClick={() => {
                                setIsOpen(false)
                                setValue(beneficiary || '')
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={`beneficiary-modal-save-btn`}
                            variant={'contained'}
                            wide
                            onClick={async () => {
                                setIsSubmitting(true)
                                await onSave(value)
                                setIsOpen(false)
                            }}
                            disabled={isSubmitting || !value}
                            loading={isSubmitting}
                        >
                            Save
                        </Button>
                    </>
                }
            >
                <Input
                    data-testid={'beneficiary-modal-input'}
                    sx={{
                        width: `704px`,
                    }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </Modal>
        </>
    )
}

export default Beneficiary
