import { ReactElement, useEffect } from 'react'
import { useBlocker, useLocation } from 'react-router-dom'
import { Userpilot } from 'userpilot'

import DiscardChangesConfirmation from 'components/DiscardChangesConfirmation'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useMixpanel from 'hooks/useMixpanel'

/**
 * This is a top-level route component that is always mounted regardless of the current route.
 * Use it for code that always runs regardless of what page you're one.
 * Add a useEffect that depends on location (from useLocation) to run code on every route change
 * i.e. whenever the user navigates to a new page (useful for things like tracking page views).
 */
const RouteChangeInterceptor = ({ children }: { children: ReactElement }) => {
    const mixpanel = useMixpanel()
    const location = useLocation()
    const { hasUnsavedChanges, setHasUnsavedChangesState, unsavedChangesCallback } =
        useHasUnsavedChanges()
    const blocker = useBlocker(hasUnsavedChanges)

    useEffect(() => {
        // If the user navigates to a new page and there are unsaved changes, block the navigation
        // hasUnsavedChanges is a global state which different components can set to true (usually forms)
        if (blocker.state === 'blocked' && !hasUnsavedChanges) {
            blocker.reset()
        }
    }, [blocker, hasUnsavedChanges])

    useEffect(() => {
        mixpanel.track('PageView', {
            path: location.pathname,
        })

        // Userpilot must be reloaded on every route change
        Userpilot.reload()
    }, [location, mixpanel])

    return (
        <>
            {children}
            {/* If the navigation was blocked --> show a dialog asking user to confirm or cancel navigation */}
            {blocker.state === 'blocked' ? (
                <DiscardChangesConfirmation
                    onConfirm={async () => {
                        await unsavedChangesCallback?.()
                        setHasUnsavedChangesState(false)
                        try {
                            blocker.proceed()
                        } catch (e) {
                            // The proceed action throws unexpected errors when the app in some specific state.
                            // Haven't been able to determine the exact cause, but the routing still goes through.
                            // To prevent the app from crashing, we catch the error here a log as a warning in console
                            console.warn('Unexpected navigation blocker error', e)
                        }
                    }}
                    onCancel={() => {
                        setHasUnsavedChangesState(true)
                        blocker.reset()
                    }}
                />
            ) : null}
        </>
    )
}

export default RouteChangeInterceptor
