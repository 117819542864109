import { AccountType } from '@lune-climate/lune'
import { AutocompleteMultiSelect } from '@lune-fe/lune-ui-lib'
import { SxProps } from '@mui/material'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { ACCOUNT_ID_QUERY_PARAM_KEY } from 'components/FiltersWithQueryParamsWrapper'
import { getAccountsById, getRecentlyUsedAccounts } from 'endpoints/dapi'
import useAccounts from 'hooks/useAccounts'
import useIsTestMode from 'hooks/useIsTestMode'
import useRecentlyUsedAccounts from 'hooks/useRecentlyUsedAccounts'
import useUpdateEffect from 'hooks/useUpdateEffect'

export interface AccountFilterProps {
    setSelectedIds: Dispatch<SetStateAction<string[]>>
    preselectedIds: string[]
    sx?: SxProps
}

export interface AccountMini {
    name: string
    id: string
}

function removeSelected(options: AccountMini[], selected: readonly AccountMini[]) {
    const selectedIds = selected.map((s) => s.id)
    return options.filter((o) => !selectedIds.includes(o.id))
}

const AccountFilter: FC<AccountFilterProps> = ({ sx, setSelectedIds, preselectedIds }) => {
    const isTestMode = useIsTestMode()
    const { activeAccount } = useAccounts()

    const [label, setLabel] = useState('')
    const [accounts, setAccounts] = useState<AccountMini[]>([])
    const [initialAccounts, setInitialAccounts] = useState<AccountMini[]>([])
    const [localSelectedAccounts, setLocalSelectedAccounts] = useState<AccountMini[]>([])
    const [loadingInitialState, setLoadingInitialState] = useState<boolean>(true)

    const { recentlyUsedAccounts } = useRecentlyUsedAccounts()

    useEffect(() => {
        if (recentlyUsedAccounts) {
            setInitialAccounts(recentlyUsedAccounts)
        }
    }, [recentlyUsedAccounts])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const paramAccountIds = params.getAll(ACCOUNT_ID_QUERY_PARAM_KEY)

        const getAccountData = async () => {
            const accounts = await getAccountsById({ accountId: preselectedIds })
            setLocalSelectedAccounts(accounts)
            setLabel('')
            setLoadingInitialState(false)
        }

        if (paramAccountIds[0] === 'none') {
            setLocalSelectedAccounts([])
            setLabel('Account')
            setLoadingInitialState(false)
        } else {
            getAccountData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useUpdateEffect(() => {
        if (activeAccount && !loadingInitialState) {
            const active = { name: activeAccount.name, id: activeAccount.id }
            setAccounts([active, ...removeSelected(initialAccounts, [active])].slice(0, 5))
            setLocalSelectedAccounts([active])
            setSelectedIds([activeAccount.id])
            setLabel('')
        }
    }, [activeAccount])

    useUpdateEffect(() => {
        setAccounts(
            [
                ...localSelectedAccounts,
                ...removeSelected(initialAccounts, localSelectedAccounts),
            ].slice(0, 5),
        )
    }, [initialAccounts])

    function handleChange(selectedItems: readonly AccountMini[]) {
        if (!selectedItems.length) {
            setSelectedIds([])
            setLocalSelectedAccounts([])
            setLabel('Account')
        } else {
            setSelectedIds([...selectedItems.map((selectedItem) => selectedItem.id)])
            setLocalSelectedAccounts([...selectedItems])
            setAccounts(
                [...selectedItems, ...removeSelected(initialAccounts, selectedItems)].slice(0, 5),
            )
            setLabel('')
        }
    }

    function handleInputChange(value: string) {
        if (value === '') {
            setAccounts(
                [
                    ...localSelectedAccounts,
                    ...removeSelected(initialAccounts, localSelectedAccounts),
                ].slice(0, 5),
            )
        } else {
            getRecentlyUsedAccounts({
                name: value,
                type: isTestMode ? AccountType.TEST : AccountType.LIVE,
            }).then((res) => setAccounts(res))
        }
    }

    return (
        <AutocompleteMultiSelect
            loadingOptions={!initialAccounts.length}
            sx={sx}
            options={accounts}
            getOptionLabel={(option) => option.name}
            // @ts-ignore
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(selectedItems) => handleChange(selectedItems)}
            value={localSelectedAccounts}
            label={label}
            // @ts-ignore
            onInputChange={(e: any, value: string) => handleInputChange(value)}
        />
    )
}

export default AccountFilter
