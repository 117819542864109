import { Text } from '@lune-fe/lune-ui-lib'
import { Stack } from '@mui/material'

import RequestResponseSnippets, { RequestMeta } from 'components/RequestResponseSnippets'

interface RequestAndResponseProps<Req, Res> {
    path: string
    request: Req
    response: Res
    idempotencyKey?: string
    metadata?: Record<string, string>
}

function RequestAndResponse<Req, Res>({
    path,
    request,
    response,
    idempotencyKey,
    metadata,
}: RequestAndResponseProps<Req, Res>) {
    return (
        <Stack spacing={4} direction={'column'}>
            <Text variant={'h6'}>{`Request & response`}</Text>
            <RequestMeta idempotencyKey={idempotencyKey} metadata={metadata} />
            <RequestResponseSnippets
                request={`curl ${process.env.REACT_APP_API_URL}${path} \\
  -X POST \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $API_KEY" \\
  -d '${JSON.stringify(request, null, 2)}'`}
                response={JSON.stringify(response, null, 2)}
            />
        </Stack>
    )
}

export default RequestAndResponse
