import { EmissionEstimate, Mass } from '@lune-climate/lune'
import { CalculationResults } from '@lune-fe/lune-components-lib'
import { BackButton, Button, LoadingWrapper, MainLayoutContainer } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { useSnackbar } from 'notistack'
import { Dispatch, ReactNode, RefObject, SetStateAction, useEffect, useState } from 'react'
import { flushSync } from 'react-dom'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'

import FormChangesDetector from 'components/FormChangesDetector'
import useAccounts from 'hooks/useAccounts'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import { SnackbarMessages } from 'SnackbarMessages'
import { EmissionCalculationFlows, EstimateType, EstimateTypeSlug } from 'views/CalculateEmissions'
const CalculateEmissionsFormWrapper = <PayloadModel,>({
    loading,
    children,
    onBack,
    onNext,
    initialFormValue,
    setUpdatedFormValue,
    emissionType,
    onCreate,
    onUpdate,
    emissionId,
    layoutScrollRef,
    emissionEstimateResult,
    emissionEstimateName,
    disableSubmitButton,
}: {
    children: ReactNode
    initialFormValue: PayloadModel
    setUpdatedFormValue?: Dispatch<SetStateAction<PayloadModel | undefined>>
    layoutScrollRef: RefObject<HTMLDivElement>
    loading?: boolean
    onCreate: (payload: PayloadModel) => Promise<EmissionEstimate> | any
    onUpdate?: (id: string, payload: PayloadModel) => Promise<EmissionEstimate> | any
    emissionId?: string
    onBack?: () => void
    onNext?: () => void
    emissionType: EstimateType
    setErrors?: Dispatch<SetStateAction<any>>
    emissionEstimateResult?: Mass
    emissionEstimateName?: string
    disableSubmitButton?: boolean
}) => {
    const navigate = useNavigate()
    const { activeAccount } = useAccounts()
    const [formValue, setFormValue] = useState<PayloadModel>({ ...initialFormValue })
    const [calculatedAmount, setCalculatedAmount] = useState<string>()
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [id, setId] = useState<string | undefined>(emissionId)
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const { setHasUnsavedChangesState } = useHasUnsavedChanges()

    useEffect(() => {
        setCalculatedAmount(undefined)
    }, [activeAccount])

    const onSubmitForm = async (values: PayloadModel) => {
        setSubmitting(true)
        await (id && onUpdate ? onUpdate(id, values) : onCreate(values))
            .catch(() => {
                snackbar(SnackbarMessages.GENERIC_ERROR)
            })
            .then((r: any) => {
                if (r.isOk()) {
                    const result = r.value
                    setCalculatedAmount(result.mass.amount)
                    setId(result.id)
                    if (onNext) {
                        layoutScrollRef.current?.scrollTo({ top: 0 })
                        onNext()
                    } else {
                        // When you call a state update function, the state does not immediately change, but it's scheduled the to happen sometime in the future
                        // flushSync is used for synchronously flushing updates, meaning everything within flushSync is processed right away before moving to the next line of code.
                        // The flushSync call is forcing setHasUnsavedChangesState(false) to update synchronously.
                        // This means that it is fully executed and completed before the function call returns,
                        // ensuring that the state value hasUnsavedChangesState has been updated to false before moving on to the navigate function.
                        flushSync(() => {
                            setHasUnsavedChangesState(false)
                        })
                        navigate(
                            `/calculate-emissions/${EstimateTypeSlug(emissionType)}/${
                                result.id
                            }/results`,
                        )
                    }
                } else {
                    snackbar(SnackbarMessages.GENERIC_ERROR)
                }
            })
            .finally(() => setSubmitting(false))
    }

    return (
        <LoadingWrapper loading={loading} sx={{ height: 1 }}>
            <MainLayoutContainer
                headerComponent={
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {!onBack ? (
                            <Button
                                iconButton
                                leftIcon={<CloseIcon />}
                                variant={'text'}
                                sx={{ ml: -1.5 }}
                                onClick={() => navigate('/calculate-emissions')}
                            />
                        ) : (
                            <BackButton sx={{ mb: 3, ml: -2 }} onClick={() => onBack()} />
                        )}
                    </Box>
                }
            >
                <CalculationResults
                    sx={{ mb: 9 }}
                    title={
                        emissionEstimateName ||
                        EmissionCalculationFlows.find((e) => 'type' in e && e.type === emissionType)
                            ?.name
                    }
                    amount={calculatedAmount || emissionEstimateResult?.amount || ''}
                />
                <FormChangesDetector initialValues={initialFormValue} newValues={formValue}>
                    <Form
                        mutators={{
                            // Used to set form field values manually
                            setValue: ([field, value], state, { changeValue }) => {
                                changeValue(state, field, () => value)
                            },
                        }}
                        onSubmit={onSubmitForm}
                        initialValues={initialFormValue}
                        render={({ handleSubmit, values, invalid, hasValidationErrors }) => {
                            setFormValue(values)
                            setUpdatedFormValue?.(values)
                            return (
                                <form
                                    style={{ position: `relative`, zIndex: 2 }}
                                    onSubmit={(form) => {
                                        if (hasValidationErrors && invalid) {
                                            snackbar('Please check the information you provided.', {
                                                variant: 'error',
                                            })
                                        }
                                        handleSubmit(form)
                                    }}
                                >
                                    <Stack direction={'column'} spacing={9}>
                                        {children}
                                    </Stack>
                                    <Button
                                        data-testid={'calculate-emissions-submit-button'}
                                        disabled={disableSubmitButton}
                                        sx={{
                                            mt: 9,
                                        }}
                                        loading={submitting}
                                        type={'submit'}
                                        variant={'contained'}
                                        leftIcon={<ArrowCircleRightIcon />}
                                    >
                                        {onNext ? 'Next' : 'Calculate'}
                                    </Button>
                                </form>
                            )
                        }}
                    />
                </FormChangesDetector>
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default CalculateEmissionsFormWrapper
