import { Button, LoadingWrapper, Modal, StandardModalHeader } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { patchOrganisation } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import usePaymentMethod from 'hooks/usePaymentMethod'
import { useIsAuthorised } from 'hooks/useUserState'
import { OrganisationDefaultPaymentMethod } from 'models/openDapi'
import AddCardForm from 'views/PaymentMethod/AddCardForm'
import PaymentMethodTile from 'views/PaymentMethod/PaymentMethodTile'
import SelectDefaultPaymentMethod from 'views/PaymentMethod/SelectDefaultPaymentMethod'

const PaymentMethod = () => {
    const { paymentMethods: addedCards } = usePaymentMethod()
    const { activeOrg, loadingActiveOrg, refetchActiveOrg } = useActiveOrganisation()
    const { refetch: refetchPaymentMethods } = usePaymentMethod()
    const isAdmin = useIsAuthorised()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [addCardView, setAddCardView] = useState<boolean>(false)
    const [isUpdatingOrg, setIsUpdatingOrg] = useState<boolean>(false)
    const [newPaymentMethod, setNewPaymentMethod] = useState<OrganisationDefaultPaymentMethod>()

    useEffect(() => {
        const m = activeOrg?.defaultPaymentMethod
        if (m) {
            setNewPaymentMethod(m)
        }
    }, [activeOrg, isModalOpen])

    const tilePaymentMethod = useMemo(() => {
        if (!activeOrg) {
            return
        }

        const { defaultPaymentMethod } = activeOrg

        if (!defaultPaymentMethod) {
            return
        }

        if (defaultPaymentMethod.type === 'invoice') {
            return 'invoice'
        }

        if (addedCards?.length) {
            return addedCards.find((c) => c.stripeId === defaultPaymentMethod.value)
        }
    }, [activeOrg, addedCards])

    const onSavePaymentClick = useCallback(async () => {
        if (!activeOrg) {
            return
        }

        setIsUpdatingOrg(true)

        await patchOrganisation(activeOrg.id, {
            defaultPaymentMethod: newPaymentMethod,
        })

        await refetchActiveOrg()

        setIsUpdatingOrg(false)

        setIsModalOpen(false)
    }, [activeOrg, newPaymentMethod, refetchActiveOrg])

    const paymentMethodControls = (
        <Box sx={{ mt: -2, justifyContent: `center`, display: `flex` }}>
            <Button
                data-testid={'cancel-select-payment-method'}
                variant={'outlined'}
                wide
                onClick={() => setIsModalOpen(false)}
                sx={{
                    mt: 0,
                    mr: 1,
                }}
            >
                Cancel
            </Button>
            <Button
                loading={isUpdatingOrg}
                onClick={onSavePaymentClick}
                data-testid={'save-payment-method'}
                variant={'contained'}
                wide
                disabled={
                    !newPaymentMethod || isEqual(newPaymentMethod, activeOrg?.defaultPaymentMethod)
                }
                type={`submit`}
                sx={{
                    mt: 0,
                }}
            >
                Save
            </Button>
        </Box>
    )

    return (
        <>
            <LoadingWrapper loading={loadingActiveOrg}>
                {activeOrg && (
                    <PaymentMethodTile
                        isReadOnly={!isAdmin}
                        paymentMethod={tilePaymentMethod}
                        onEdit={() => setIsModalOpen(true)}
                    />
                )}
            </LoadingWrapper>

            <Modal
                header={
                    <StandardModalHeader
                        title={`Organisation payment method`}
                        subTitle={`Applies to all purchases made with this organisation`}
                    />
                }
                actions={addCardView ? undefined : paymentMethodControls}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                {!addCardView && (
                    <SelectDefaultPaymentMethod
                        selectedMethod={newPaymentMethod}
                        onChange={(pm) => setNewPaymentMethod(pm)}
                        onAdd={() => setAddCardView(true)}
                    />
                )}
                {addCardView && (
                    <AddCardForm
                        onSuccess={async (id) => {
                            setAddCardView(false)
                            await refetchPaymentMethods()
                            if (id) {
                                setNewPaymentMethod({ type: `card`, value: id })
                            }
                        }}
                        onBack={() => setAddCardView(false)}
                    />
                )}
            </Modal>
        </>
    )
}

export default PaymentMethod
